import React from "react";
import BackgroundLottie from "../components/Lottie/Lottie";
import MobLottie from "../components/mobLottie/mobLottie";
import useResizeWidth from "@hooks/useResizeWidth";
import SEO from "../components/seo";

const mobileScreenWidth = 992;


const Index = () => {
    const { width } = useResizeWidth();

    return (
        <>
            <SEO title={"Home"} />
            {width && width < mobileScreenWidth ? (<MobLottie />) : (<BackgroundLottie />)}
        </>
    );
};

export default Index;
