import React from "react"
import Lottie from "react-lottie"
import {data} from "./data"
import classes from "./Lottie.module.css"

const BackgroundLottie = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className={classes.container}>
      <Lottie options={defaultOptions} isStopped={false} isPaused={false} isClickToPauseDisabled/>
    </div>
  )
};

export default BackgroundLottie;
